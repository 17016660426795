import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Popover,
  Radio,
  Slider,
  Switch,
  TreeSelect,
  Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import en_GB from 'antd/lib/locale/en_GB';
import { format, parse, subDays } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import { removeEmptyObject } from '../../../utils/removeEmptyObject';
import { SelectItem } from '../../../components/form';
import { SubcategoryFormItem } from '../../../components/filter/SubcategoryFormItem';
import FilterService from '../../../services/filter';
import { Barcode, Brand, LocalCompany } from '../../../models';
import { fetchLocalCompany } from '../../../utils/fetchLocalCompany';
import { fetchBrand } from '../../../utils/fetchBrand';
import { fetchBarcode } from '../../../utils/fetchBarcode';
import {
  useAddress,
  useBarcodes,
  useBrands,
  useCities,
  useLocalCompanies,
  useSubcategories,
} from '../../../components/filter/hook';
import styles from './styles.module.scss';
import { fetchAddress } from '../../../utils/fetchAddress';

const { Text } = Typography;
const CustomDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = CustomDatePicker;

type Props = {
  type?: string;
  pageName?: string;
  onFilterChange?: () => void;
  onCancel?: () => void;
  disabledMode?: boolean;
  isExtrapolated?: boolean;
  setExtrapolated?: (checked: boolean) => void;
  isLastThreeMonth?: boolean;
  localCompanyBrandRequired?: boolean;
  isDynamicValuesHidden?: boolean;
  reportName?: string;
  isBarcodeRequired?: boolean;
};

const defaultValues = {
  monthsRange: [] as string[],
  subcategory: [] as string[],
  city: [] as string[],
  local_company: [] as string[],
};

const searchMode = {
  include: 'include',
  exclude: 'exclude',
};

const formatter = (value: number) => `${value}%`;

export const StoresFilter = ({
  type,
  pageName,
  onCancel,
  onFilterChange,
  disabledMode,
  isExtrapolated = false,
  setExtrapolated,
  isLastThreeMonth = false,
  localCompanyBrandRequired = false,
  isDynamicValuesHidden = false,
  reportName = '',
  isBarcodeRequired = false,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [filtersForm] = Form.useForm();
  const [brand, setBrand] = useState<Brand[]>([]);
  const [localCompany, setLocalCompany] = useState<LocalCompany[]>([]);
  const [barcodes, setBarcodes] = useState<Barcode[]>([]);
  const [address, setAddress] = useState([]);
  const [lastTransaction, setLastTransation] = useState<string>('');
  const [share, setShare] = useState<number>(0);

  const [subcategoriesArray, setSubcategoriesArray] = useState([]);
  const [brandsArray, setBrandsArray] = useState([]);
  const [companiesArray, setCompaniesArray] = useState([]);
  const [barcodesArray, setBarcodesArray] = useState([]);
  const [addressArray, setAddressArray] = useState([]);

  const [brandsString, setBrandsString] = useState<string>('');
  const [companyString, setCompanyString] = useState<string>('');
  const [addressString, setAddressString] = useState('');
  const [subcategorysString, setSabcategoryString] = useState<string>('');
  const [barcodeString, setBarcodeString] = useState<string>('');

  const [additionalItems, setAdditinalItems] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const valuesFromUrl = qs.parse(history.location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const defaultRegions = JSON.parse(localStorage.getItem('user'))?.role?.regions?.map(String);

  const { data: citiesData, isFetching: citiesIsFetching } = useCities(isExtrapolated);
  const { data: subcategoriesData, isFetching: subcategoriesIsFetching } = useSubcategories();
  const { data: addressData, isFetching: addressIsFetching } = useAddress(addressString);
  const { data: localCompaniesData, isFetching: localCompaniesIsFetching } = useLocalCompanies(
    subcategorysString,
    companyString
  );
  const { data: brandsData, isFetching: brandsIsFetching } = useBrands(
    type,
    companyString,
    subcategorysString,
    brandsString,
    valuesFromUrl
  );
  const { data: barcodesData, isFetching: barcodesIsFetching } = useBarcodes(
    companyString,
    subcategorysString,
    brandsString,
    barcodeString,
    valuesFromUrl
  );

  const validateMessages = { required: 'Required field!' };
  const yesterday = subDays(new Date(), 1);

  const localCompanyWatch = Form.useWatch('local_company', filtersForm);
  const brandWatch = Form.useWatch('brand', filtersForm);
  const barcodeWatch = Form.useWatch('barcode', filtersForm);
  const modeWatch = Form.useWatch('mode', filtersForm);

  const isLocalCompanyFilled = localCompanyWatch?.length > 0;
  const isBrandFilled = brandWatch?.length > 0;
  const isBarcodeFilled = barcodeWatch?.length > 0;

  const shouldShowLabelForLocalCompany = isLocalCompanyFilled && !isBrandFilled && !isBarcodeFilled;
  const shouldShowLabelForBrand = isLocalCompanyFilled && isBrandFilled && !isBarcodeFilled;
  const shouldShowLabelForBarcode = isLocalCompanyFilled && isBrandFilled && isBarcodeFilled;

  const isLocalCompanyRequired = (pageName: string, type: string) =>
    localCompanyBrandRequired ||
    pageName === 'abc-analysis' ||
    (pageName === 'trends-share' && (type === 'company' || type === 'brand'));

  const isBrandRequired = (pageName: string) => pageName === 'co-existence';
  const brandRequired =
    (pageName === 'trends-share' || isBrandRequired(pageName) || localCompanyBrandRequired) && type === 'brand';

  const initialValues = {
    ...(pageName === 'stores' && {
      dates: [
        valuesFromUrl?.period_after ? parse(valuesFromUrl?.period_after, 'yyyy-MM-dd', new Date()) : yesterday,
        valuesFromUrl?.period_after ? parse(valuesFromUrl?.period_before, 'yyyy-MM-dd', new Date()) : yesterday,
      ],
      mode: true,
    }),
    ...(valuesFromUrl?.local_company && {
      local_company: valuesFromUrl?.local_company.split(',').map(Number) || [],
    }),
    subcategory_store: valuesFromUrl?.subcategory_store ? valuesFromUrl?.subcategory_store?.split(',').map(Number) : [],
    subcategory_sale: valuesFromUrl?.subcategory_sale ? valuesFromUrl?.subcategory_sale?.split(',').map(Number) : [''],
    city: valuesFromUrl?.city?.split(',') || (pageName !== 'comparison' ? defaultRegions : []),
    ...(valuesFromUrl?.brand && { brand: valuesFromUrl?.brand.split(',').map(Number) || [] }),
    ...(valuesFromUrl?.barcode && { barcode: valuesFromUrl?.barcode.split(',').map(Number) || [] }),
    ...(valuesFromUrl?.size_of_package && {
      size_of_package: valuesFromUrl?.size_of_package?.split(',') || [],
    }),
    mode: valuesFromUrl?.mode || searchMode.include,
    ...(valuesFromUrl?.type_of_package && {
      type_of_package: valuesFromUrl?.type_of_package?.split(',').map(Number) || [],
    }),
    ...(valuesFromUrl?.flavor && {
      flavor: valuesFromUrl?.flavor?.split(',').map(Number) || [],
    }),
    ...(valuesFromUrl?.concentration && { concentration: valuesFromUrl?.concentration?.split(',') || [] }),
    ...(valuesFromUrl?.liquid_volume && { liquid_volume: valuesFromUrl?.liquid_volume?.split(',') || [] }),
    ...(valuesFromUrl?.puffs && { puffs: valuesFromUrl?.puffs?.split(',') || [] }),
    ...(valuesFromUrl?.salt_nicotine && { salt_nicotine: valuesFromUrl?.salt_nicotine?.split(',') || [] }),
    ...(valuesFromUrl?.battery_capacity && {
      battery_capacity: valuesFromUrl?.battery_capacity?.split(',') || [],
    }),
  };

  const onFinish = (values: any) => {
    if (values?.dates) {
      const sinceDate = format(values?.dates[0], 'yyyy-MM-dd');
      const toDate = format(values?.dates[1], 'yyyy-MM-dd');
      values = {
        ...values,
        period_after: sinceDate,
        period_before: toDate,
        mode: searchMode[values?.mode],
      };
      delete values.dates;
    }

    if (values.barcode_product_name) {
      const barcode = values.barcode_product_name;
      delete values.barcode_product_name;
      values = {
        ...values,
        barcode: barcode,
      };
    }

    if (values.share) {
      const shareArr = values?.share;
      delete values.share;
      values = {
        ...values,
        share_min: shareArr[0],
        share_max: shareArr[1],
      };
    }

    let normalized = {} as any;
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        if (Array.isArray(values[key])) {
          normalized[key] = values[key].join(',');
        } else {
          normalized[key] = values[key];
        }
      }
    });
    history.replace({
      pathname: history.location.pathname,
      search: `page=1&limit=10&${qs.stringify(removeEmptyObject(normalized))}`,
    });

    onFilterChange();
  };

  const onReset = () => {
    history.replace({
      pathname: history.location.pathname,
      search: `page=1&limit=10&`,
    });
    filtersForm.setFieldsValue({
      ...defaultValues,
      ...(valuesFromUrl?.brand && { brand: [] as string[] }),
      ...(valuesFromUrl?.barcode && { barcode: [] as string[] }),
    });
  };

  const fetchLastTransaction = useCallback(() => {
    FilterService.getLastTransaction(reportName).then((res) => {
      setLastTransation(res.data?.last_transaction);
    });
  }, [reportName]);

  const fetchAdditionalFilters = useCallback(() => {
    if (isDynamicValuesHidden === false) {
      const arr: any = [];
      FilterService.getAvailableFilters(subcategorysString)
        .then((res) => {
          const promises = res?.data?.results.map(async (item: any) => {
            return await FilterService.getNewOptions(
              item?.filter_url,
              undefined,
              item.filter_url === 'type_of_package' ? 'priority, type_of_packages' : undefined
            ).then((response) => {
              const newItem = {
                name: item.name_val,
                label: item?.label,
                type: item?.type,
                options:
                  item?.type === 'select'
                    ? response?.data?.map((obj: any) => ({
                        label: obj[item.name_val],
                        value: obj['id'] ? obj['id'] : obj[item.name_val],
                      }))
                    : response?.data?.results,
              };
              arr.push(newItem);
              return newItem;
            });
          });
          return Promise.all(promises);
        })
        .then((newItems) => {
          setAdditinalItems(newItems);
        })
        .catch((err) => console.error(err));
    }
  }, [isDynamicValuesHidden, subcategorysString]);

  const handleSearchAddress = (value) => {
    if (value?.length >= 1) {
      fetchAddress(value, setAddress);
    }
  };

  const handleSearchLocalCompany = (value) => {
    if (value?.length >= 3) {
      fetchLocalCompany(value, setLocalCompany, subcategorysString);
    }
  };

  const handleSearchBrand = (value) => {
    if (value?.length >= 3) {
      fetchBrand(value, setBrand, subcategorysString, companyString);
    }
  };

  const handleSearchBarcode = (value) => {
    if (value?.length >= 3) {
      fetchBarcode(value, setBarcodes, subcategorysString, companyString, brandsString);
    }
  };

  const handleAddressChange = useCallback(
    (value) => {
      setAddressString(typeof value === 'object' ? value?.join(',') : value);
    },
    [setAddressString]
  );

  const handleLocalCompanyChange = useCallback(
    (value) => {
      setCompanyString(typeof value === 'object' ? value?.join(',') : value);
    },
    [setCompanyString]
  );

  const handleDropdownVisibleLocalCompanyChange = useCallback(
    (open) => {
      if (!open) {
        handleLocalCompanyChange(companiesArray);
      }
    },
    [handleLocalCompanyChange, companiesArray]
  );

  const handleDropdownVisibleAddressChange = useCallback(
    (open) => {
      if (!open) {
        handleAddressChange(addressArray);
      }
    },
    [handleAddressChange, addressArray]
  );

  const handleDeselectAddressValue = useCallback(
    (val) => {
      const arr = addressArray?.filter((el) => el !== val);
      handleAddressChange(arr);
    },
    [handleAddressChange, addressArray]
  );

  const handleDeselectLocalCompanyValue = useCallback(
    (val) => {
      const arr = companiesArray?.filter((el) => el !== val);
      handleLocalCompanyChange(arr);
    },
    [handleLocalCompanyChange, companiesArray]
  );

  const handleBrandChange = useCallback(
    (value) => {
      setBrandsString(typeof value === 'object' ? value?.join(',') : value);
    },
    [setBrandsString]
  );

  const handleDropdownVisibleBrandChange = useCallback(
    (open) => {
      if (!open) {
        handleBrandChange(brandsArray);
      }
    },
    [handleBrandChange, brandsArray]
  );

  const handleDeselectBrandValue = useCallback(
    (val) => {
      const arr = brandsArray?.filter((el) => el !== val);
      handleBrandChange(arr);
    },
    [handleBrandChange, brandsArray]
  );

  const handleBarcodeChange = useCallback(
    (value) => {
      setBarcodeString(typeof value === 'object' ? value?.join(',') : value);
    },
    [setBarcodeString]
  );

  const handleDropdownVisibleBarcodeChange = useCallback(
    (open) => {
      if (!open) {
        handleBarcodeChange(barcodesArray);
      }
    },
    [handleBarcodeChange, barcodesArray]
  );

  const handleDeselectBarcodeValue = useCallback(
    (val) => {
      const arr = barcodesArray?.filter((el) => el !== val);
      handleBarcodeChange(arr);
    },
    [handleBarcodeChange, barcodesArray]
  );

  useEffect(() => {
    fetchLastTransaction();
    fetchAdditionalFilters();
  }, [fetchLastTransaction, fetchAdditionalFilters]);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    if (Object.keys(params).length === 0) {
      filtersForm.resetFields(null);
    }
  }, [location.search, filtersForm]);

  useEffect(() => {
    if (addressData) {
      setAddress(addressData);
    }
  }, [addressData]);

  useEffect(() => {
    if (localCompaniesData) {
      setLocalCompany(localCompaniesData);
    }
  }, [localCompaniesData]);

  useEffect(() => {
    if (brandsData) {
      setBrand(brandsData);
    }
  }, [brandsData]);

  useEffect(() => {
    if (barcodesData) {
      setBarcodes(barcodesData);
    }
  }, [barcodesData]);

  return (
    <ConfigProvider locale={en_GB}>
      <Form
        form={filtersForm}
        layout="vertical"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={initialValues}
      >
        <div className={styles.filter_container}>
          {pageName === 'stores' && (
            <>
              <Form.Item>
                <label className={pageName === 'stores' ? styles.required : ''}>
                  Date {lastTransaction && `(up to date ${lastTransaction})`}
                </label>

                <Form.Item name="dates" noStyle rules={[{ required: pageName === 'stores' }]}>
                  <RangePicker
                    format="YYYY-MM-DD"
                    picker="date"
                    allowClear={true}
                    style={{ width: '100%' }}
                    className={styles.ant_calendar}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <label>Address/Store ID</label>
                <Form.Item name="hashed_store_id" noStyle>
                  <SelectItem
                    name="hashed_store_id"
                    disabledMode={disabledMode}
                    mode="multiple"
                    options={address}
                    placeholder="Address/Store ID"
                    loading={addressIsFetching}
                    maxTagCount="responsive"
                    allowClear={true}
                    onDropdownVisibleChange={handleDropdownVisibleAddressChange}
                    onDeselect={handleDeselectAddressValue}
                    onSearch={handleSearchAddress}
                    onChange={(value) => {
                      if (disabledMode) {
                        handleAddressChange(value);
                      } else {
                        setAddressArray(value);
                      }
                    }}
                  />
                </Form.Item>
              </Form.Item>
            </>
          )}

          {pageName === 'sales' && (
            <div>
              <label>Extrapolated mode: </label>
              <Switch
                onChange={(checked) => {
                  setExtrapolated(checked);
                  filtersForm.setFieldsValue({ city: [] });
                }}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
            </div>
          )}
          <Form.Item>
            <label>Region</label>
            <Form.Item name="city" noStyle>
              <TreeSelect
                treeCheckable={true}
                loading={citiesIsFetching}
                labelInValue={false}
                placeholder="Please select"
                maxTagCount="responsive"
                allowClear
                treeDefaultExpandedKeys={['all']}
                filterTreeNode
                treeNodeFilterProp="title"
                treeData={[
                  {
                    title: <span>Select all</span>,
                    value: 'all',
                    key: 'all',
                    children: citiesData,
                  },
                ]}
              />
            </Form.Item>
          </Form.Item>
          {/* {pageName === 'stores' && (
            <div>
              <label>
                <Popover content={<SearchModeInfo />} trigger="hover">
                  <InfoCircleOutlined />
                </Popover>
                &nbsp;&nbsp;&nbsp; Search mode: &nbsp;&nbsp;
              </label>
              <Form.Item name="mode" valuePropName="checked" label="Search mode:" noStyle>
                <Switch checkedChildren="Include" unCheckedChildren="Exclude" />
              </Form.Item>
            </div>
          )} */}
          <Divider style={{ border: '0.5px solid #d9d9d9' }} />
          <Form.Item name="mode" noStyle>
            <Radio.Group
              defaultValue={modeWatch}
              buttonStyle="solid"
              size="middle"
              style={{ width: '100%', textAlign: 'center', marginBottom: '8px' }}
            >
              <Radio.Button value={searchMode.include} style={{ width: '50%' }}>
                Include
              </Radio.Button>
              <Radio.Button value={searchMode.exclude} style={{ width: '50%' }}>
                Exclude
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <SubcategoryFormItem
            name="subcategory_store"
            label="Subcategory"
            setSabcategoryString={setSabcategoryString}
            subcategoriesArray={subcategoriesArray}
            subcategoriesData={subcategoriesData}
            subcategoriesIsFetching={subcategoriesIsFetching}
            setSubcategoriesArray={setSubcategoriesArray}
          />

          <Form.Item>
            <label className={isLocalCompanyRequired(pageName, type) ? styles.required : ''}>Local company</label>
            {shouldShowLabelForLocalCompany && modeWatch}
            <SelectItem
              name="local_company"
              rules={[{ required: isLocalCompanyRequired(pageName, type) }]}
              disabledMode={disabledMode}
              mode="multiple"
              options={localCompany}
              placeholder="Choose a local company"
              loading={localCompaniesIsFetching}
              maxTagCount="responsive"
              allowClear={true}
              onDropdownVisibleChange={handleDropdownVisibleLocalCompanyChange}
              onDeselect={handleDeselectLocalCompanyValue}
              onSearch={handleSearchLocalCompany}
              onChange={(value) => {
                if (disabledMode) {
                  handleLocalCompanyChange(value);
                } else {
                  setCompaniesArray(value);
                }
              }}
            />
          </Form.Item>

          {(type === 'brand' || type === 'barcode' || pageName === 'co-existence') && (
            <Form.Item>
              <label className={brandRequired ? styles.required : ''}>Brand</label>
              {shouldShowLabelForBrand && <Text type="secondary">&nbsp;{modeWatch}</Text>}
              <SelectItem
                name="brand"
                rules={[{ required: brandRequired }]}
                disabledMode={disabledMode}
                mode="multiple"
                options={brand}
                placeholder="Choose a brand"
                loading={brandsIsFetching}
                maxTagCount="responsive"
                allowClear={true}
                onDropdownVisibleChange={handleDropdownVisibleBrandChange}
                onDeselect={handleDeselectBrandValue}
                onSearch={handleSearchBrand}
                onChange={(value) => {
                  if (disabledMode) {
                    handleBrandChange(value);
                  } else {
                    setBrandsArray(value);
                  }
                }}
              />
            </Form.Item>
          )}

          {(type === 'barcode' || pageName === 'co-existence') && (
            <Form.Item>
              <label className={pageName === 'trends-share' || isBarcodeRequired ? styles.required : ''}>
                Product name
                {shouldShowLabelForBarcode && <Text type="secondary">&nbsp;{modeWatch}</Text>}
              </label>
              <SelectItem
                disabledMode={disabledMode}
                name="barcode"
                rules={[{ required: pageName === 'trends-share' || isBarcodeRequired }]}
                mode="multiple"
                options={barcodes || []}
                placeholder="Choose a product name"
                loading={barcodesIsFetching}
                maxTagCount="responsive"
                allowClear={true}
                filterOption={false}
                onDropdownVisibleChange={handleDropdownVisibleBarcodeChange}
                onDeselect={handleDeselectBarcodeValue}
                onSearch={handleSearchBarcode}
                onChange={(value) => {
                  if (disabledMode) {
                    handleBarcodeChange(value);
                  } else {
                    setBarcodesArray(value);
                  }
                }}
              />
            </Form.Item>
          )}

          <Divider style={{ border: '0.5px solid #d9d9d9' }} />

          <SubcategoryFormItem
            name="subcategory_sale"
            label="Show sale"
            setSabcategoryString={setSabcategoryString}
            subcategoriesArray={subcategoriesArray}
            subcategoriesData={subcategoriesData}
            subcategoriesIsFetching={subcategoriesIsFetching}
            setSubcategoriesArray={setSubcategoriesArray}
            isAllSelected
          />

          <Form.Item>
            <label>My share</label>
            <Form.Item name="share">
              <Slider range tooltip={{ formatter }} defaultValue={[0, 100]} />
            </Form.Item>
          </Form.Item>

          {additionalItems &&
            additionalItems?.map((item: any) => {
              switch (item?.type) {
                case 'tree':
                  return (
                    <Form.Item>
                      <label>{item?.label}</label>
                      <Form.Item name={`${item.name}`} noStyle>
                        <TreeSelect
                          treeData={item.options}
                          treeCheckable={true}
                          placeholder="Select the items"
                          maxTagCount="responsive"
                          allowClear
                        />
                      </Form.Item>
                    </Form.Item>
                  );
                case 'select':
                  return (
                    <Form.Item>
                      <label>{item?.label}</label>
                      <SelectItem
                        name={`${item.name}`}
                        mode="multiple"
                        options={item?.options}
                        placeholder="Select the items"
                        maxTagCount="responsive"
                        allowClear={true}
                      />
                    </Form.Item>
                  );
                default:
                  return '';
              }
            })}
          {(pageName === 'trends-share' || pageName === 'trends' || pageName === 'comparison') && (
            <Form.Item style={{ display: 'flex', flexDirection: 'column' }}>
              <label>
                <Popover
                  content={
                    <>
                      <p style={{ width: '300px', height: '70px' }}>
                        Common stores - означает, то что за указанный период в отчете, каждый месяц участвуют одни и те
                        же магазины.
                      </p>
                    </>
                  }
                  trigger="hover"
                >
                  <InfoCircleOutlined />
                </Popover>
                &nbsp;&nbsp;&nbsp; Common stores&nbsp;&nbsp;
              </label>

              <Form.Item name="active_stores" valuePropName="checked" noStyle>
                <Checkbox />
              </Form.Item>
            </Form.Item>
          )}
        </div>

        <div className={styles.buttons_wrapper}>
          <span style={{ color: '#1890FF', fontSize: '14px', cursor: 'pointer' }} onClick={() => onReset()}>
            Reset
          </span>
          <div>
            <Button style={{ marginRight: '10px' }} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </ConfigProvider>
  );
};
