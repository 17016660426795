import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Popover,
  Switch,
  TreeSelect,
  Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import en_GB from 'antd/lib/locale/en_GB';
import { format, parse } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import { removeEmptyObject } from '../../utils/removeEmptyObject';
import FilterService from '../../services/filter';
import { dateRange } from '../../utils/lastThreeMonths';
import { SelectItem } from '../form';
import { Barcode, Brand, LocalCompany } from '../../models';
import { MultipleDates } from './MultipleDates';
import { fetchLocalCompany } from '../../utils/fetchLocalCompany';
import { fetchBrand } from '../../utils/fetchBrand';
import { fetchBarcode } from '../../utils/fetchBarcode';
import { useBarcodes, useBrands, useCities, useLocalCompanies, useSubcategories } from './hook/useFilterHook';
import { SubcategoryFormItem } from './SubcategoryFormItem';
import { SearchModeInfo } from './SearchModeInfo';
import styles from './styles.module.scss';

const { Text } = Typography;

const CustomDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = CustomDatePicker;

type Props = {
  type?: string;
  pageName?: string;
  onFilterChange?: () => void;
  onCancel?: () => void;
  disabledMode?: boolean;
  isExtrapolated?: boolean;
  setExtrapolated?: (checked: boolean) => void;
  isLastThreeMonth?: boolean;
  localCompanyBrandRequired?: boolean;
  isDynamicValuesHidden?: boolean;
  reportName?: string;
  isBarcodeRequired?: boolean;
};

const defaultValues = {
  monthsRange: [] as string[],
  subcategory: [] as string[],
  city: [] as string[],
  local_company: [] as string[],
};

const searchMode = {
  true: 'include',
  false: 'exclude',
};

export const CustomFilter = ({
  type,
  pageName,
  onCancel,
  onFilterChange,
  disabledMode,
  isExtrapolated = false,
  setExtrapolated,
  isLastThreeMonth = false,
  localCompanyBrandRequired = false,
  isDynamicValuesHidden = false,
  reportName = '',
  isBarcodeRequired = false,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [filtersForm] = Form.useForm();
  const [brand, setBrand] = useState<Brand[]>([]);
  const [localCompany, setLocalCompany] = useState<LocalCompany[]>([]);
  const [barcodes, setBarcodes] = useState<Barcode[]>([]);
  const [lastTransaction, setLastTransation] = useState<string>('');

  const [subcategoriesArray, setSubcategoriesArray] = useState([]);
  const [brandsArray, setBrandsArray] = useState([]);
  const [companiesArray, setCompaniesArray] = useState([]);
  const [barcodesArray, setBarcodesArray] = useState([]);

  const [brandsString, setBrandsString] = useState<string>('');
  const [companyString, setCompanyString] = useState<string>('');
  const [subcategorysString, setSabcategoryString] = useState<string>('');
  const [barcodeString, setBarcodeString] = useState<string>('');

  const [additionalItems, setAdditinalItems] = useState([]);

  const valuesFromUrl = qs.parse(history.location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const defaultRegions = JSON.parse(localStorage.getItem('user'))?.role?.regions?.map(String);

  const { data: citiesData, isFetching: citiesIsFetching } = useCities(isExtrapolated);
  const { data: subcategoriesData, isFetching: subcategoriesIsFetching } = useSubcategories();
  const { data: localCompaniesData, isFetching: localCompaniesIsFetching } = useLocalCompanies(
    subcategorysString,
    companyString
  );
  const { data: brandsData, isFetching: brandsIsFetching } = useBrands(
    type,
    companyString,
    subcategorysString,
    brandsString,
    valuesFromUrl
  );
  const { data: barcodesData, isFetching: barcodesIsFetching } = useBarcodes(
    companyString,
    subcategorysString,
    brandsString,
    barcodeString,
    valuesFromUrl
  );

  const currentMonth = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyyMM');
  const yearAgoMonth = format(new Date().setMonth(new Date().getMonth() - 6), 'yyyyMM');
  const lastThreeMonth = format(new Date().setMonth(new Date().getMonth() - 3), 'yyyyMM');

  const startMonth = valuesFromUrl?.months?.split(',')[0] || null;
  const endMonth = valuesFromUrl?.months?.split(',')[valuesFromUrl?.months?.split(',').length - 1] || null;
  const validateMessages = { required: 'Required field!' };
  const modeWatch = Form.useWatch('mode', filtersForm);
  const isLocalCompanyRequired = (pageName: string, type: string) =>
    localCompanyBrandRequired ||
    pageName === 'abc-analysis' ||
    (pageName === 'trends-share' && (type === 'company' || type === 'brand'));

  const isBrandRequired = (pageName: string) => pageName === 'co-existence';
  const brandRequired =
    (pageName === 'trends-share' || isBrandRequired(pageName) || localCompanyBrandRequired) && type === 'brand';

  const isLocalCompanyDisabled = subcategorysString !== '' && pageName === 'stores';
  const isBrandDisabled = subcategorysString !== '' && pageName === 'stores';
  const isProductDisabled = subcategorysString !== '' && pageName === 'stores';
  const isSubcategoryDisabled =
    (companyString !== '' ||
      brandsString !== '' ||
      barcodeString !== '' ||
      companiesArray.length ||
      brandsArray.length ||
      barcodesArray.length) &&
    pageName === 'stores';

  const parseYearMonth = (yearMonth: string) => parse(yearMonth, 'yyyyMM', new Date());

  const initialValues = {
    ...(pageName !== 'comparison' && {
      monthsRange:
        pageName === 'trends'
          ? valuesFromUrl?.months
            ? [parseYearMonth(startMonth), parseYearMonth(endMonth)]
            : [parse(yearAgoMonth, 'yyyyMM', new Date()), parse(currentMonth, 'yyyyMM', new Date())]
          : valuesFromUrl?.months
          ? [parseYearMonth(startMonth), parseYearMonth(endMonth)]
          : [
              parse(isLastThreeMonth ? lastThreeMonth : currentMonth, 'yyyyMM', new Date()),
              parse(currentMonth, 'yyyyMM', new Date()),
            ],
    }),
    ...(pageName === 'comparison' && {
      period: valuesFromUrl?.period_1_after
        ? [
            {
              period: [
                parse(valuesFromUrl?.period_1_after, 'yyyy-MM-dd', new Date()),
                parse(valuesFromUrl?.period_1_after, 'yyyy-MM-dd', new Date()),
              ],
            },
            {
              period: [
                parse(valuesFromUrl?.period_2_after, 'yyyy-MM-dd', new Date()),
                parse(valuesFromUrl?.period_2_after, 'yyyy-MM-dd', new Date()),
              ],
            },
          ]
        : [{}, {}],
    }),
    ...(valuesFromUrl?.local_company && {
      local_company: valuesFromUrl?.local_company.split(',').map(Number) || [],
    }),
    subcategory: valuesFromUrl?.subcategory ? valuesFromUrl?.subcategory?.split(',').map(Number) : [],
    city: valuesFromUrl?.city?.split(',') || (pageName !== 'comparison' ? defaultRegions : []),
    ...(valuesFromUrl?.brand_ && { brand: valuesFromUrl?.brand.split(',').map(Number) || [] }),
    ...(valuesFromUrl?.barcode && { barcode: valuesFromUrl?.barcode.split(',').map(Number) || [] }),
    ...(valuesFromUrl?.size_of_package && {
      size_of_package: valuesFromUrl?.size_of_package?.split(',') || [],
    }),
    ...(valuesFromUrl?.type_of_package && {
      type_of_package: valuesFromUrl?.type_of_package?.split(',').map(Number) || [],
    }),
    ...(valuesFromUrl?.flavor && {
      flavor: valuesFromUrl?.flavor?.split(',').map(Number) || [],
    }),
    ...(valuesFromUrl?.concentration && { concentration: valuesFromUrl?.concentration?.split(',') || [] }),
    ...(valuesFromUrl?.liquid_volume && { liquid_volume: valuesFromUrl?.liquid_volume?.split(',') || [] }),
    ...(valuesFromUrl?.puffs && { puffs: valuesFromUrl?.puffs?.split(',') || [] }),
    ...(valuesFromUrl?.salt_nicotine && { salt_nicotine: valuesFromUrl?.salt_nicotine?.split(',') || [] }),
    ...(valuesFromUrl?.battery_capacity && {
      battery_capacity: valuesFromUrl?.battery_capacity?.split(',') || [],
    }),
  };

  const onFinish = (values: any) => {
    if (values.monthsRange) {
      const months = dateRange(format(values?.monthsRange[0], 'yyyy-MM'), format(values?.monthsRange[1], 'yyyy-MM'));
      delete values.monthsRange;
      values = {
        ...values,
        months: months,
      };
    }

    if (values.period) {
      const period = values.period
        .map((item: any, index: number) => {
          return {
            [`period_${index + 1}_after`]: format(new Date(item?.period[0]), 'yyyy-MM-dd'),
            [`period_${index + 1}_before`]: format(new Date(item?.period[1]), 'yyyy-MM-dd'),
          };
        })
        .reduce((obj: any, item: any) => Object.assign(obj, item), {});
      delete values.period;
      values = {
        ...values,
        ...period,
      };
    }

    if (values.barcode_product_name) {
      const barcode = values.barcode_product_name;
      delete values.barcode_product_name;
      values = {
        ...values,
        barcode: barcode,
      };
    }

    let normalized = {} as any;
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        if (Array.isArray(values[key])) {
          normalized[key] = values[key].join(',');
        } else {
          normalized[key] = values[key];
        }
      }
    });
    history.replace({
      pathname: history.location.pathname,
      search: `page=1&limit=10&${qs.stringify(removeEmptyObject(normalized))}`,
    });

    onFilterChange();
  };

  const onReset = () => {
    history.replace({
      pathname: history.location.pathname,
      search: `page=1&limit=10&`,
    });
    filtersForm.setFieldsValue({
      ...defaultValues,
      ...(valuesFromUrl?.brand && { brand: [] as string[] }),
      ...(valuesFromUrl?.barcode && { barcode: [] as string[] }),
    });
  };

  const fetchLastTransaction = useCallback(() => {
    FilterService.getLastTransaction(reportName).then((res) => {
      setLastTransation(res.data?.last_transaction);
    });
  }, [reportName]);

  const fetchAdditionalFilters = useCallback(() => {
    if (isDynamicValuesHidden === false) {
      const arr: any = [];
      FilterService.getAvailableFilters(subcategorysString)
        .then((res) => {
          const promises = res?.data?.results.map(async (item: any) => {
            return await FilterService.getNewOptions(
              item?.filter_url,
              undefined,
              item.filter_url === 'type_of_package' ? 'priority, type_of_packages' : undefined
            ).then((response) => {
              const newItem = {
                name: item.name_val,
                label: item?.label,
                type: item?.type,
                options:
                  item?.type === 'select'
                    ? response?.data?.map((obj: any) => ({
                        label: obj[item.name_val],
                        value: obj['id'] ? obj['id'] : obj[item.name_val],
                      }))
                    : response?.data?.results,
              };
              arr.push(newItem);
              return newItem;
            });
          });
          return Promise.all(promises);
        })
        .then((newItems) => {
          setAdditinalItems(newItems);
        })
        .catch((err) => console.error(err));
    }
  }, [isDynamicValuesHidden, subcategorysString]);

  const handleSearchLocalCompany = (value) => {
    if (value?.length >= 3) {
      fetchLocalCompany(value, setLocalCompany, subcategorysString);
    }
  };

  const handleSearchBrand = (value) => {
    if (value?.length >= 3) {
      fetchBrand(value, setBrand, subcategorysString, companyString);
    }
  };

  const handleSearchBarcode = (value) => {
    if (value?.length >= 3) {
      fetchBarcode(value, setBarcodes, subcategorysString, companyString, brandsString);
    }
  };

  const handleLocalCompanyChange = useCallback(
    (value) => {
      setCompanyString(typeof value === 'object' ? value?.join(',') : value);
    },
    [setCompanyString]
  );

  const handleDropdownVisibleLocalCompanyChange = useCallback(
    (open) => {
      if (!open) {
        handleLocalCompanyChange(companiesArray);
      }
    },
    [handleLocalCompanyChange, companiesArray]
  );

  const handleDeselectLocalCompanyValue = useCallback(
    (val) => {
      const arr = companiesArray?.filter((el) => el !== val);
      handleLocalCompanyChange(arr);
    },
    [handleLocalCompanyChange, companiesArray]
  );

  const handleBrandChange = useCallback(
    (value) => {
      setBrandsString(typeof value === 'object' ? value?.join(',') : value);
    },
    [setBrandsString]
  );

  const handleDropdownVisibleBrandChange = useCallback(
    (open) => {
      if (!open) {
        handleBrandChange(brandsArray);
      }
    },
    [handleBrandChange, brandsArray]
  );

  const handleDeselectBrandValue = useCallback(
    (val) => {
      const arr = brandsArray?.filter((el) => el !== val);
      handleBrandChange(arr);
    },
    [handleBrandChange, brandsArray]
  );

  const handleBarcodeChange = useCallback(
    (value) => {
      setBarcodeString(typeof value === 'object' ? value?.join(',') : value);
    },
    [setBarcodeString]
  );

  const handleDropdownVisibleBarcodeChange = useCallback(
    (open) => {
      if (!open) {
        handleBarcodeChange(barcodesArray);
      }
    },
    [handleBarcodeChange, barcodesArray]
  );

  const handleDeselectBarcodeValue = useCallback(
    (val) => {
      const arr = barcodesArray?.filter((el) => el !== val);
      handleBarcodeChange(arr);
    },
    [handleBarcodeChange, barcodesArray]
  );

  useEffect(() => {
    fetchLastTransaction();
    fetchAdditionalFilters();
  }, [fetchLastTransaction, fetchAdditionalFilters]);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    if (Object.keys(params).length === 0) {
      filtersForm.resetFields(null);
    }
  }, [location.search, filtersForm]);

  useEffect(() => {
    if (localCompaniesData) {
      setLocalCompany(localCompaniesData);
    }
  }, [localCompaniesData]);

  useEffect(() => {
    if (brandsData) {
      setBrand(brandsData);
    }
  }, [brandsData]);

  useEffect(() => {
    if (barcodesData) {
      setBarcodes(barcodesData);
    }
  }, [barcodesData]);

  return (
    <ConfigProvider locale={en_GB}>
      <Form
        form={filtersForm}
        layout="vertical"
        key="form"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={initialValues}
      >
        <div className={styles.filter_container}>
          {pageName === 'comparison' && (
            <div className={styles.period}>
              <Text type="secondary">
                Select comparison periods {lastTransaction && `(up to date ${lastTransaction})`}
              </Text>
              <MultipleDates name="period" />
            </div>
          )}
          {pageName !== 'comparison' && pageName !== 'stores' && (
            <Form.Item>
              <label className={pageName === 'sales' || pageName === 'abc-analysis' ? styles.required : ''}>
                Months {lastTransaction && `(up to date ${lastTransaction})`}
              </label>
              <Form.Item name="monthsRange" noStyle rules={[{ required: pageName === 'sales' }]}>
                <RangePicker
                  format="YYYY-MM"
                  picker="month"
                  allowClear={true}
                  style={{ width: '100%' }}
                  className={styles.ant_calendar}
                />
              </Form.Item>
            </Form.Item>
          )}

          {pageName === 'stores' && (
            <>
              <Form.Item>
                <label className={pageName === 'stores' ? styles.required : ''}>
                  Date {lastTransaction && `(up to date ${lastTransaction})`}
                </label>

                <Form.Item name="dates" noStyle rules={[{ required: pageName === 'stores' }]}>
                  <RangePicker
                    format="YYYY-MM-DD"
                    picker="date"
                    allowClear={true}
                    style={{ width: '100%' }}
                    className={styles.ant_calendar}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <label>Store ID</label>
                <Form.Item name="hashed_stores" noStyle>
                  <Input placeholder="Store ID" />
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <label>Address</label>
                <Form.Item name="address" noStyle>
                  <Input placeholder="Address" />
                </Form.Item>
              </Form.Item>
            </>
          )}

          {pageName === 'sales' && (
            <div>
              <label>Extrapolated mode: </label>
              <Switch
                onChange={(checked) => {
                  setExtrapolated(checked);
                  filtersForm.setFieldsValue({ city: [] });
                }}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
            </div>
          )}
          <Form.Item>
            <label>Region</label>
            <Form.Item name="city" noStyle>
              <TreeSelect
                treeCheckable={true}
                loading={citiesIsFetching}
                labelInValue={false}
                placeholder="Please select"
                maxTagCount="responsive"
                allowClear
                treeDefaultExpandedKeys={['all']}
                filterTreeNode
                treeNodeFilterProp="title"
                treeData={[
                  {
                    title: <span>Select all</span>,
                    value: 'all',
                    key: 'all',
                    children: citiesData,
                  },
                ]}
              />
            </Form.Item>
          </Form.Item>
          {pageName === 'stores' && (
            <div>
              <label>
                <Popover content={<SearchModeInfo />} trigger="hover">
                  <InfoCircleOutlined />
                </Popover>
                &nbsp;&nbsp;&nbsp; Search mode: &nbsp;&nbsp;
              </label>
              <Form.Item name="mode" valuePropName="checked" label="Search mode:" noStyle>
                <Switch checkedChildren="Include" unCheckedChildren="Exclude" />
              </Form.Item>
            </div>
          )}
          <SubcategoryFormItem
            name="subcategory"
            label="Subcategory"
            setSabcategoryString={setSabcategoryString}
            subcategoriesArray={subcategoriesArray}
            subcategoriesData={subcategoriesData}
            subcategoriesIsFetching={subcategoriesIsFetching}
            setSubcategoriesArray={setSubcategoriesArray}
            disabled={isSubcategoryDisabled}
          />

          <Form.Item>
            <label className={isLocalCompanyRequired(pageName, type) ? styles.required : ''}>Local company</label>
            <SelectItem
              name="local_company"
              rules={[{ required: isLocalCompanyRequired(pageName, type) }]}
              disabledMode={disabledMode}
              mode="multiple"
              options={localCompany}
              placeholder="Choose a local company"
              loading={localCompaniesIsFetching}
              maxTagCount="responsive"
              allowClear={true}
              onDropdownVisibleChange={handleDropdownVisibleLocalCompanyChange}
              onDeselect={handleDeselectLocalCompanyValue}
              onSearch={handleSearchLocalCompany}
              disabled={isLocalCompanyDisabled}
              onChange={(value) => {
                if (disabledMode) {
                  handleLocalCompanyChange(value);
                } else {
                  setCompaniesArray(value);
                }
              }}
            />
          </Form.Item>

          {(type === 'brand' || type === 'barcode' || pageName === 'co-existence') && (
            <Form.Item>
              <label className={brandRequired ? styles.required : ''}>Brand</label>
              <SelectItem
                name="brand"
                rules={[{ required: brandRequired }]}
                disabledMode={disabledMode}
                mode="multiple"
                options={brand}
                placeholder="Choose a brand"
                loading={brandsIsFetching}
                maxTagCount="responsive"
                allowClear={true}
                onDropdownVisibleChange={handleDropdownVisibleBrandChange}
                onDeselect={handleDeselectBrandValue}
                onSearch={handleSearchBrand}
                disabled={isBrandDisabled}
                onChange={(value) => {
                  if (disabledMode) {
                    handleBrandChange(value);
                  } else {
                    setBrandsArray(value);
                  }
                }}
              />
            </Form.Item>
          )}

          {(type === 'barcode' || pageName === 'co-existence') && (
            <Form.Item>
              <label className={pageName === 'trends-share' || isBarcodeRequired ? styles.required : ''}>
                Product name
              </label>
              <SelectItem
                disabledMode={disabledMode}
                name="barcode"
                rules={[{ required: pageName === 'trends-share' || isBarcodeRequired }]}
                mode="multiple"
                options={barcodes || []}
                placeholder="Choose a product name"
                loading={barcodesIsFetching}
                maxTagCount="responsive"
                allowClear={true}
                filterOption={false}
                onDropdownVisibleChange={handleDropdownVisibleBarcodeChange}
                onDeselect={handleDeselectBarcodeValue}
                onSearch={handleSearchBarcode}
                disabled={isProductDisabled}
                onChange={(value) => {
                  if (disabledMode) {
                    handleBarcodeChange(value);
                  } else {
                    setBarcodesArray(value);
                  }
                }}
              />
            </Form.Item>
          )}

          {additionalItems &&
            additionalItems?.map((item: any) => {
              switch (item?.type) {
                case 'tree':
                  return (
                    <Form.Item>
                      <label>{item?.label}</label>
                      <Form.Item name={`${item.name}`} noStyle>
                        <TreeSelect
                          treeData={item.options}
                          treeCheckable={true}
                          placeholder="Select the items"
                          maxTagCount="responsive"
                          allowClear
                        />
                      </Form.Item>
                    </Form.Item>
                  );
                case 'select':
                  return (
                    <Form.Item>
                      <label>{item?.label}</label>
                      <SelectItem
                        name={`${item.name}`}
                        mode="multiple"
                        options={item?.options}
                        placeholder="Select the items"
                        maxTagCount="responsive"
                        allowClear={true}
                      />
                    </Form.Item>
                  );
                default:
                  return '';
              }
            })}
          {(pageName === 'trends-share' || pageName === 'trends' || pageName === 'comparison') && (
            <Form.Item style={{ display: 'flex', flexDirection: 'column' }}>
              <label>
                <Popover
                  content={
                    <>
                      <p style={{ width: '300px', height: '70px' }}>
                        Common stores - означает, то что за указанный период в отчете, каждый месяц участвуют одни и те
                        же магазины.
                      </p>
                    </>
                  }
                  trigger="hover"
                >
                  <InfoCircleOutlined />
                </Popover>
                &nbsp;&nbsp;&nbsp; Common stores&nbsp;&nbsp;
              </label>

              <Form.Item name="active_stores" valuePropName="checked" noStyle>
                <Checkbox />
              </Form.Item>
            </Form.Item>
          )}
        </div>

        <div className={styles.buttons_wrapper}>
          <span style={{ color: '#1890FF', fontSize: '14px', cursor: 'pointer' }} onClick={() => onReset()}>
            Reset
          </span>
          <div>
            <Button style={{ marginRight: '10px' }} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </ConfigProvider>
  );
};
