import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { notification, Pagination, PaginationProps, Table, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import qs from 'qs';

import { dateRange } from '../../../utils/lastThreeMonths';
import MarketShareService from '../../../services/marketShare';
import { isTobacco } from '../../../utils/isTobacco';
import { MarketShareCard } from './MarketShareCard';
import { Loader } from '../../../components/loader/Loader';
import { useColumns } from './useColumns';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  pageName: string;
  rule?: any;
  hiddenColumns: any;
};

export const MarketShareTable = ({ pageName, rule, hiddenColumns }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;
  const [totalInfo, setTotalInfo] = useState<any[]>([]);
  const regions = rule?.regions?.join(',');

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching, error } = useQuery(['market-share-table', pageName, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    switch (pageName) {
      case 'company':
        return MarketShareService.getMarketShareByCompany(
          {
            months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
            city_id: params?.city || regions || '24',
            subcategory_id: params?.subcategory,
            limit: +params?.limit || 10,
            ...params,
          },
          { signal }
        );

      case 'brand':
        return MarketShareService.getMarketShareByBrand(
          {
            months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
            city_id: params?.city || regions || '24',
            subcategory_id: params?.subcategory,
            limit: +params?.limit || 10,
            ...params,
          },
          { signal }
        );

      case 'barcode':
        return MarketShareService.getMarketShareByBarcode(
          {
            months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
            city_id: params?.city || regions || '24',
            subcategory_id: params?.subcategory,
            limit: +params?.limit || 10,
            ...params,
          },
          { signal }
        );
      default:
        return;
    }
  });

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification(error?.response?.data['months'][0]);
        setTableData([]);
        setTotal(0);
        setTotalInfo([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
        setTotal(0);
        setTotalInfo([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
        setTotalInfo([]);
      }
    }

    return () => {
      setTableData([]);
      setTotal(0);
      setTotalInfo([]);
    };
  }, [data, error]);

  const columnsParams = useMemo(
    () => ({ isTabletOrMobile, tableData, hiddenColumns }),
    [isTabletOrMobile, tableData, hiddenColumns]
  );
  const columns = useColumns(columnsParams);

  return (
    <>
      {!isTabletOrMobile ? (
        <Table
          columns={columns}
          dataSource={tableData}
          loading={isFetching}
          pagination={{
            pageSize: limit,
            defaultPageSize: 10,
            total: total,
            onChange: onPageChange,
            current: page,
            defaultCurrent: 1,
          }}
          className={styles.ant_table}
          sticky
          scroll={{ x: 1300 }}
          summary={() => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} align="left">
                    <Text className={styles.total_bold}>Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="left">
                    <Text className={styles.total_bold}>{totalInfo[0]?.stores || '--'}</Text>
                  </Table.Summary.Cell>
                  {totalInfo[0]?.data?.map((item: any, index: number) => (
                    <>
                      {/* {!hiddenColumns?.value_share?.value && (
                      <Table.Summary.Cell index={index} className={index % 2 === 0 ? styles.column_gray : ''}>
                        <Text className={styles.total_bold}>{`${item?.value_share} %` || '--'}</Text>
                      </Table.Summary.Cell>
                    )} */}
                      {!hiddenColumns?.value?.value && (
                        <Table.Summary.Cell
                          align="right"
                          index={index}
                          className={index % 2 === 0 ? styles.column_gray : ''}
                        >
                          <span className={styles.summary_cell}>
                            <Text className={styles.total_bold}>
                              {`${item?.value}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ') || '--'}
                            </Text>
                            <Text className={styles.total_bold}>Share: {item?.value_share || '--'} %</Text>
                          </span>
                        </Table.Summary.Cell>
                      )}
                      {!hiddenColumns?.items?.value && (
                        <Table.Summary.Cell
                          align="right"
                          index={index}
                          className={index % 2 === 0 ? styles.column_gray : ''}
                        >
                          <span className={styles.summary_cell}>
                            <Text className={styles.total_bold}>
                              {`${item?.items?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}` || '--'}
                            </Text>
                            <Text className={styles.total_bold}>Share: {item?.items_share || '--'} %</Text>
                          </span>
                        </Table.Summary.Cell>
                      )}
                      {isTobacco() ||
                        (!hiddenColumns?.volume?.value && (
                          <Table.Summary.Cell
                            align="right"
                            index={index}
                            className={index % 2 === 0 ? styles.column_gray : ''}
                          >
                            <span className={styles.summary_cell}>
                              <Text className={styles.total_bold}>
                                {`${item?.volume?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}` || '--'}
                              </Text>
                              <Text className={styles.total_bold}>Share: {item?.volume_share || '--'} %</Text>
                            </span>
                          </Table.Summary.Cell>
                        ))}
                      {!hiddenColumns?.numeric_distribution?.value && (
                        <Table.Summary.Cell
                          align="right"
                          index={index}
                          className={index % 2 === 0 ? styles.column_gray : ''}
                        >
                          <Text className={styles.total_bold}>{item?.numeric_distribution || '--'}</Text>
                        </Table.Summary.Cell>
                      )}
                      {!hiddenColumns?.weighted_distribution?.value && (
                        <Table.Summary.Cell
                          align="right"
                          index={index}
                          className={index % 2 === 0 ? styles.column_gray : ''}
                        >
                          <Text className={styles.total_bold}>{item?.weighted_distribution || '--'}</Text>
                        </Table.Summary.Cell>
                      )}
                    </>
                  ))}
                </Table.Summary.Row>
              </>
            );
          }}
          bordered
          size="middle"
        />
      ) : isFetching ? (
        <Loader />
      ) : (
        tableData && (
          <div style={{ marginTop: 16 }}>
            <MarketShareCard
              tableData={tableData}
              isTabletOrMobile={isTabletOrMobile}
              // periodLabels={periodLabels}
              totalData={totalInfo}
            />
            <Pagination
              size="small"
              onChange={onPageChange}
              total={total}
              current={page}
              pageSize={limit}
              defaultPageSize={10}
              defaultCurrent={1}
            />
          </div>
        )
      )}
    </>
  );
};
