import { Button, Menu, MenuProps, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import Logout from '../../assets/logout.svg';

const SalesMappedTitle = {
  '/sales': 'Sales statistics',
  '/trends': 'Trends',
  '/comparison': 'Comparison',
};

const ShareMappedTitle = {
  '/market-share': 'Market share',
  '/trends-share': 'Trends share',
  '/comparison-share': 'Comparison share',
};

const AnalyticsMappedTitle = {
  '/abc-analysis': 'ABC analysis',
  '/co-existence': 'Co-Existance',
  '/stores': 'Stores',
  '/out-of-stock': 'Out of Stock',
  '/buying-behavior': 'Buying behavior',
  '/price-elasticity': 'Price elasticity',
  '/comparison-product': 'Sell in / Sell out Prices',
  '/co-existence-trends': 'CoExistence trends',
  '/vpo-segmentation': 'VPO - Segmentation',
  '/vpo-trends': 'VPO - Segmentation Trends',
};

const ManageMappedTitle = {
  '/permissions/users': 'Users',
  '/permissions/roles': 'Roles',
  '/users-logs': 'User logs',
};

export const Items = (permissions: any, companyId?: number, title?: string): MenuProps['items'] => [
  {
    label: SalesMappedTitle[title] || 'Sales Report',
    key: 'sales',
    children: [
      (permissions?.sales_statistics_local_company?.includes('can_read') ||
        permissions?.sales_statistics_brand?.includes('can_read') ||
        permissions?.sales_statistics_barcode?.includes('can_read')) && {
        type: 'group',
        label: '',
        children: [
          permissions?.sales_statistics_local_company?.includes('can_read') && {
            label: <Link to="/sales/company">Sales statistics</Link>,
            key: '/sales',
          },
          permissions?.trends_local_company?.includes('can_read') && {
            label: <Link to="/trends/company/value">Trends</Link>,
            key: '/trends',
          },
          permissions?.comparison_local_company?.includes('can_read') && {
            label: <Link to="/comparison/company/value">Comparison</Link>,
            key: '/comparison',
          },
        ],
      },
    ],
  },
  {
    label: ShareMappedTitle[title] || 'Share by cities',
    key: 'trends',
    children: [
      (permissions?.share_trends_local_company?.includes('can_read') ||
        permissions?.share_trends_brand?.includes('can_read') ||
        permissions?.share_trends_barcode?.includes('can_read')) && {
        type: 'group',
        label: '',
        children: [
          permissions?.market_share_local_company?.includes('can_read') && {
            label: <Link to="/market-share/company">Market</Link>,
            key: '/market-share',
          },
          permissions?.share_trends_local_company?.includes('can_read') && {
            label: <Link to="/trends-share/company">Trends</Link>,
            key: '/trends-share',
          },
          permissions?.share_comparison_local_company?.includes('can_read') && {
            label: <Link to="/comparison-share/company">Comparison</Link>,
            key: '/comparison-share',
          },
        ],
      },
    ],
  },
  permissions?.sales_by_stores?.includes('can_read') && {
    label: <Link to="/stores">Stores</Link>,
    key: '/stores',
  },
  {
    label: AnalyticsMappedTitle[title] || 'Analytics',
    key: 'analytics',
    children: [
      {
        type: 'group',
        label: '',
        children: [
          permissions?.abc_analysis_barcode?.includes('can_read') && {
            label: <Link to={`/abc-analysis/value`}>ABC analysis</Link>,
            key: `/abc-analysis`,
          },
          permissions?.out_of_stock_barcode?.includes('can_read') && {
            label: <Link to="/out-of-stock">Out of stock</Link>,
            key: '/out-of-stock',
          },
          (permissions?.buying_behavior_price?.includes('can_read') ||
            permissions?.buying_behavior_week?.includes('can_read') ||
            permissions?.buying_behavior_volume?.includes('can_read') ||
            permissions?.coexistence?.includes('can_read')) && {
            label: <Link to="/buying-behavior">Buying behavior</Link>,
            key: '/buying-behavior',
          },
          permissions?.price_elasticity?.includes('can_read') && {
            label: <Link to="/price-elasticity">Price elasticity</Link>,
            key: '/price-elasticity',
          },
          permissions?.product_comparison?.includes('can_read') && {
            label: <Link to="/comparison-product">Sell in / Sell out Prices</Link>,
            key: '/comparison-product',
          },
          permissions?.coexistence?.includes('can_read') && {
            label: <Link to="/co-existence">Co-Existence</Link>,
            key: '/co-existence',
          },
          permissions?.coexistence?.includes('can_read') && {
            label: <Link to="/co-existence-trends">Co-Existence Trends</Link>,
            key: '/co-existence-trends',
          },
          permissions?.vpo_segmentation?.includes('can_read') && {
            label: <Link to="/vpo-segmentation">VPO Segmentation</Link>,
            key: '/vpo-segmentation',
          },
          permissions?.vpo_trend?.includes('can_read') && {
            label: <Link to="/vpo-trends">VPO Segmentation Trends</Link>,
            key: '/vpo-trends',
          },
          // (permissions?.basket_analysis?.includes('can_read') || companyId === 601) && {
          //   label: <Link to="/basket-analysis">Basket Analysis</Link>,
          //   key: '/basket-analysis',
          // },
        ],
      },
    ],
  },
  (permissions?.users?.includes('can_read') ||
    permissions?.roles?.includes('can_read') ||
    permissions?.journal?.includes('can_read')) && {
    label: ManageMappedTitle[title] || 'Manage',
    key: 'permissions',
    children: [
      permissions?.users?.includes('can_read') && {
        label: <Link to="/permissions/users">Users</Link>,
        key: '/permissions/users',
      },
      permissions?.roles?.includes('can_read') && {
        label: <Link to="/permissions/roles">Roles</Link>,
        key: '/permissions/roles',
      },
      permissions?.journal?.includes('can_read') && {
        label: <Link to="/users-logs">Users logs</Link>,
        key: '/users-logs',
      },
    ],
  },
];

export const UserMenuItems = (
  logout?: () => void,
  openFilter?: () => void,
  categories?: any[],
  defaultCategory?: string | number,
  setDefaultCategory?: (val: string) => void,
  openDownloads?: () => void,
  user?: any
): MenuProps => ({
  items: [
    {
      label: (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <UserOutlined />
            <p style={{ margin: 0, padding: 0, marginLeft: 4 }}>{user?.username}</p>
          </div>
          <Menu.Item key="logout">
            <div onClick={logout}>
              <img src={Logout} alt="logout" style={{ marginLeft: 5 }} />
            </div>
          </Menu.Item>
        </div>
      ),
      key: 'user',
    },
    {
      label: <div onClick={openFilter}>Change password</div>,
      key: 'password',
    },
    {
      label: (
        <Popover
          placement="leftTop"
          content={categories?.map((item) => {
            return (
              <Button
                key={item?.id}
                type={item?.id === defaultCategory ? 'primary' : 'text'}
                onClick={(e) => {
                  setDefaultCategory(item?.id);
                  localStorage.setItem('category', item.id);
                  window.location.reload();
                }}
              >
                {item?.name_val}
              </Button>
            );
          })}
          title="Categories"
        >
          <div>Change category</div>
        </Popover>
      ),
      key: 'category',
    },
    {
      label: <div onClick={openDownloads}>Downloads</div>,
      key: 'downloads',
    },
  ],
});
